import React, { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import logoUrl from "../assets/logo.png";
import { contactInformation } from "../constants";
import routes, { contact } from "../routes/routes";
import Modal from "./modal";
import { login } from "../httpService";
import { UserContext } from "../context/UserContext";
import Button from "./button";

const Footer = () => {
  const [adminLoginModalOpened, setAdminLoginModalOpened] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { onLogin } = useContext(UserContext);

  const doLogin = useCallback(async () => {
    const response = await login({
      email,
      password,
    });

    const data = await response.json();

    onLogin(data.email, data.jwt, data.admin);
    setAdminLoginModalOpened(false);
  }, [email, password]);

  return (
    <>
      <footer className="bg-secondary text-white text-sm pt-24 p-4">
        <div className="container mx-auto max-w-7xl">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-0 flex-wrap justify-between">
            <div className="mb-6 space-y-2 col-span-1 md:col-span-1">
              <Link to="/" className="flex items-center mb-4">
                <img src={logoUrl} alt="AS Maskiner" className="h-10" />
              </Link>
            </div>

            <div className="mb-6 col-span-1 md:col-span-1">
              <h3 className="font-bold mb-2">Köp och sälj</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to={routes.usedMachines.url}
                    className="hover:underline"
                  >
                    Begagnade maskiner
                  </Link>
                </li>
                <li>
                  <Link to={routes.newMachines.url} className="hover:underline">
                    Nya maskiner
                  </Link>
                </li>
              </ul>
            </div>

            <div className="mb-6 col-span-1 md:col-span-1">
              <h3 className="font-bold mb-2">Vårt företag</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/about-us" className="hover:underline">
                    Om oss
                  </Link>
                </li>
                <li>
                  <Link to="/jobs" className="hover:underline">
                    Jobb
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions" className="hover:underline">
                    Allmänna villkor
                  </Link>
                </li>
              </ul>
            </div>

            <div className="mb-6 col-span-1 md:col-span-1">
              <h3 className="font-bold mb-2">Kontakt</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/faq" className="hover:underline">
                    Frågor och svar
                  </Link>
                </li>
                <li>
                  <Link to={contact.url} className="hover:underline">
                    Support
                  </Link>
                </li>
                <li>
                  <Link
                    to={`tel:${contactInformation.telephoneNumber}`}
                    className="hover:underline"
                  >
                    {contactInformation.telephoneNumber}
                  </Link>
                </li>
                <li
                  onClick={() => setAdminLoginModalOpened(true)}
                  className="cursor-pointer"
                >
                  Admin
                </li>
              </ul>
            </div>
          </div>

          <div className="border-t border-gray-700 mt-4 pt-4">
            <p>AS Maskiner Sverige AB © {new Date().getFullYear()}</p>
          </div>
        </div>
      </footer>
      {adminLoginModalOpened && (
        <Modal
          header="Admin login"
          showCloseButton={false}
          onClose={() => {
            setAdminLoginModalOpened(false);
          }}
        >
          <div className="flex flex-col gap-4">
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email
              </label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    doLogin();
                  }
                }}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Lösenord
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    doLogin();
                  }
                }}
              />
            </div>
            <Button onClick={() => doLogin()}>Logga in</Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Footer;
