import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import { createMachine } from "../httpService";
import { MachineContext } from "../context/MachineContext";
import Input from "../components/input";
import Select from "../components/select";
import MarkdownDisplay from "../components/markdownDisplay";

const FILE_SIZE_LIMIT = 50;

const CreateMachine = () => {
  const { isAdmin, getJwt, initialized } = useContext(UserContext);
  const { reinitialize } = useContext(MachineContext);
  const navigate = useNavigate();

  const [machineData, setMachineData] = useState({
    externalProviderId: "",
    provider: "",
    providerUrl: "",
    condition: "",
    title: "",
    buildYear: "",
    attributes: [{ key: "", value: "" }],
    description: "",
    primaryImage: null,
    subImages: [],
    videos: [],
    category: "",
    countryCode: "",
    city: "",
    postalCode: "",
    street: "",
    manufacturer: "",
    price: "",
    currency: "",
  });

  const [conditionError, setConditionError] = useState(null);
  const [titleError, setTitleError] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [manufacturerError, setManufacturerError] = useState(null);
  const [subImagesError, setSubImagesError] = useState(null);
  const [primaryImageError, setPrimaryImageError] = useState(null);
  const [attributesError, setAttributesError] = useState(null);

  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSendingRequest(true);

    let hasErrors = false;
    if (!machineData.condition) {
      setConditionError("Skick måste väljas");
      hasErrors = true;
    } else {
      setConditionError(null);
    }

    if (!machineData.title) {
      setTitleError("Titel måste fyllas i");
      hasErrors = true;
    } else {
      setTitleError(null);
    }

    if (!machineData.category) {
      setCategoryError("Kategori måste fyllas i");
      hasErrors = true;
    } else {
      setCategoryError(null);
    }

    if (!machineData.manufacturer) {
      setManufacturerError("Fabrikat måste fyllas i");
      hasErrors = true;
    } else {
      setManufacturerError(null);
    }

    if (!machineData.subImages.length) {
      setSubImagesError("Underbilder måste skickas med");
      hasErrors = true;
    } else {
      setSubImagesError(null);
    }

    if (!machineData.primaryImage) {
      setPrimaryImageError("Primar bild måste skickas med");
      hasErrors = true;
    } else {
      setPrimaryImageError(null);
    }

    if (
      machineData.attributes.filter(
        (attribute) => !attribute.key || !attribute.value
      ).length > 0
    ) {
      setAttributesError("Alla nycklar & värden måste fyllas i");
      hasErrors = true;
    } else {
      setAttributesError(null);
    }

    if (hasErrors) {
      setIsSendingRequest(false);
      return;
    }

    const formData = new FormData();

    for (const [key, value] of Object.entries(machineData)) {
      if (
        key !== "attributes" &&
        key !== "subImages" &&
        key !== "videos" &&
        !!value
      ) {
        formData.append(key, value);
      }
    }

    machineData.subImages.forEach((image) => {
      formData.append("subImage", image);
    });

    machineData.videos.forEach((video) => {
      formData.append("video", video);
    });

    machineData.attributes.forEach((attribute, index) => {
      formData.append("attribute", JSON.stringify(attribute));
    });

    createMachine({
      formData,
      jwt: getJwt(),
    })
      .then(async (res) => {
        const json = await res.json();

        await reinitialize();

        navigate(`/machines/${json.id}`);
      })
      .catch((err) => {
        if (err.status == 409) {
          alert(
            "En maskin med detta leverantörs-id och leverantör finns redan."
          );
        } else {
          alert("Kunde inte skapa maskin");
        }
      })
      .finally(() => setIsSendingRequest(false));
  };

  const handleInputChange = (e) => {
    setMachineData({ ...machineData, [e.target.name]: e.target.value });
  };

  const handleAttributeChange = (index, key, value) => {
    const newAttributes = machineData.attributes.map((attr, i) =>
      i === index ? { ...attr, [key]: value } : attr
    );
    setMachineData({ ...machineData, attributes: newAttributes });
  };

  const addAttribute = () => {
    setMachineData({
      ...machineData,
      attributes: [...machineData.attributes, { key: "", value: "" }],
    });
  };

  const removeAttribute = (index) => {
    const newAttributes = machineData.attributes.filter((_, i) => i !== index);
    setMachineData({ ...machineData, attributes: newAttributes });
  };

  const handleSubImageUpload = (e) => {
    const newImages = Array.from(e.target.files);

    setMachineData({
      ...machineData,
      subImages: [...machineData.subImages, ...newImages],
    });
  };

  const removeSubImage = (index) => {
    const updatedImages = machineData.subImages.filter((_, i) => i !== index);

    setMachineData({
      ...machineData,
      subImages: updatedImages,
    });
  };

  const handlePrimaryImageUpload = (e) => {
    const newImage = e.target.files[0];

    setMachineData({
      ...machineData,
      primaryImage: newImage,
    });
  };

  const handleVideosUploadUpload = (e) => {
    const newVideos = Array.from(e.target.files);

    setMachineData({
      ...machineData,
      videos: [...machineData.videos, ...newVideos],
    });
  };

  const removeVideo = (index) => {
    const updatedVideos = machineData.videos.filter((_, i) => i !== index);

    setMachineData({
      ...machineData,
      videos: updatedVideos,
    });
  };

  useEffect(() => {
    if (initialized && !isAdmin) {
      navigate("/");
    }
  }, [isAdmin, initialized]);

  const totalFileSizeInMb = machineData.primaryImage
    ? machineData.subImages
        .concat(machineData.videos)
        .concat([machineData.primaryImage])
        .map((i) => Math.round(i.size * 0.000001 * 100) / 100)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    : null;

  return (
    <div className="container mx-auto p-8 max-w-7xl">
      <h2 className="text-3xl font-bold mb-8">Skapa maskin</h2>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="flex flex-row gap-2">
          <div className="w-full">
            <Input
              id="externalProviderId"
              label="Artikelnummer hos leverantör"
              type="text"
              name="externalProviderId"
              value={machineData.externalProviderId}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full">
            <Select
              id="provider"
              name="provider"
              label="Leverantör"
              value={machineData.provider}
              onChange={handleInputChange}
              options={[
                {
                  value: "hoechsmann",
                  display: "Hoechsmann",
                },
              ]}
            />
          </div>
        </div>
        <div>
          <Input
            id="providerUrl"
            label="Länk hos leverantör"
            type="text"
            name="providerUrl"
            value={machineData.providerUrl}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="w-full">
            <Select
              id="condition"
              name="condition"
              label="Skick"
              required
              value={machineData.condition}
              onChange={handleInputChange}
              error={conditionError}
              options={[
                {
                  value: "new",
                  display: "Ny",
                },
                {
                  value: "used",
                  display: "Begagnad",
                },
              ]}
            />
          </div>
          <div className="w-full">
            <Input
              id="buildYear"
              label="Årsmodell"
              name="buildYear"
              type="number"
              value={machineData.buildYear}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div>
          <Input
            id="title"
            label="Titel"
            type="text"
            name="title"
            required
            error={titleError}
            value={machineData.title}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label
            htmlFor="description"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Beskrivning{" "}
            <a
              href="https://www.markdownguide.org/cheat-sheet/"
              target="_blank"
              className="text-blue-300"
            >
              (markdown)
            </a>
          </label>
          <textarea
            rows="4"
            id="description"
            name="description"
            value={machineData.description}
            onChange={handleInputChange}
            className="appearance-none border border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight"
          />
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Förhandsvisning
          </label>
          <MarkdownDisplay sx="border p-4" data={machineData.description} />
        </div>
        <div>
          <Input
            id="category"
            label="Kategori"
            type="text"
            name="category"
            required
            error={categoryError}
            value={machineData.category}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="w-full">
            <Input
              id="countryCode"
              label="Landskod"
              type="text"
              name="countryCode"
              maxLength={2}
              value={machineData.countryCode}
              onChange={(e) =>
                setMachineData({
                  ...machineData,
                  [e.target.name]: e.target.value.toUpperCase(),
                })
              }
            />
          </div>
          <div className="w-full">
            <Input
              id="city"
              label="Stad"
              type="text"
              name="city"
              value={machineData.city}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="w-full">
            <Input
              id="postalCode"
              label="Postkod"
              type="text"
              name="postalCode"
              value={machineData.postalCode}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full">
            <Input
              id="street"
              label="Väg"
              type="text"
              name="street"
              value={machineData.street}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div>
          <Input
            id="manufacturer"
            label="Fabrikat"
            type="text"
            required
            name="manufacturer"
            error={manufacturerError}
            value={machineData.manufacturer}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="w-full">
            <Input
              id="price"
              label="Pris"
              type="number"
              name="price"
              value={machineData.price}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full">
            <Input
              id="currency"
              label="Valuta"
              type="text"
              name="currency"
              value={machineData.currency}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-bold mb-4">Tekniska specifikationer</h3>
          {machineData.attributes.map((attribute, index) => (
            <div key={index} className="flex justify-end items-end mb-4 gap-2">
              <div className="flex-1">
                <Input
                  id={`key_${index}`}
                  label="Nyckel"
                  type="text"
                  name="key"
                  error={attributesError}
                  value={attribute.key}
                  onChange={(e) =>
                    handleAttributeChange(index, "key", e.target.value)
                  }
                />
              </div>
              <div className="flex-1">
                <Input
                  id={`value_${index}`}
                  label="Värde"
                  type="text"
                  name="value"
                  error={attributesError}
                  value={attribute.value}
                  onChange={(e) =>
                    handleAttributeChange(index, "value", e.target.value)
                  }
                />
              </div>
              <Button onClick={() => removeAttribute(index)}>Ta bort</Button>
            </div>
          ))}
          <Button onClick={addAttribute}>Lägg till specifikation</Button>
        </div>

        <div>
          <Input
            id="primaryImage"
            label="Primär bild"
            required
            type="file"
            accept="image/*"
            error={primaryImageError}
            onChange={handlePrimaryImageUpload}
          />
        </div>

        <div>
          <Input
            id="subImages"
            label="Underbilder"
            required
            type="file"
            accept="image/*"
            multiple
            error={subImagesError}
            onChange={handleSubImageUpload}
          />
          {machineData.subImages.map((image, index) => (
            <div key={index}>
              {image.name}
              <Button onClick={() => removeSubImage(index)} sx="ml-4">
                Ta bort
              </Button>
            </div>
          ))}
        </div>

        <div>
          <Input
            id="videos"
            label="Videor"
            required
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            multiple
            onChange={handleVideosUploadUpload}
          />
          {machineData.videos.map((video, index) => (
            <div key={index}>
              {video.name}
              <Button onClick={() => removeVideo(index)} sx="ml-4">
                Ta bort
              </Button>
            </div>
          ))}
        </div>

        {totalFileSizeInMb && (
          <div
            className={`${
              totalFileSizeInMb > FILE_SIZE_LIMIT ? "text-red-500" : ""
            }`}
          >
            Total filstorlek: {totalFileSizeInMb}mb/{FILE_SIZE_LIMIT}mb
          </div>
        )}
        <Button
          type="submit"
          sx="mt-4 w-full"
          loading={isSendingRequest}
          disabled={totalFileSizeInMb > FILE_SIZE_LIMIT}
        >
          Skapa maskin
        </Button>
      </form>
    </div>
  );
};

export default CreateMachine;
