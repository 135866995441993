const routes = {
  usedMachines: {
    url: "/catalogue?condition=Använd",
    name: "Begagnade maskiner",
  },
  newMachines: {
    url: "/catalogue?condition=Ny",
    name: "Nya maskiner",
  },
  sellMachines: {
    url: "/machines/sell",
    name: "Sälj maskin",
  },
  /*financing: {
    url: "/financing",
    name: "Finansiering",
  },*/
  aboutUs: {
    url: "/about-us",
    name: "Om oss",
  },
};

export const contact = {
  url: "/contact",
  name: "Kontakt",
};

export default routes;
